
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import PlayerNavigationVue from "@/components/player/navigation.vue";
import PlayerResourcesNavigationVue from "@/components/player/resources/navigation.vue";
import AppPlayerResourcesExercisesListIndexVue from "@/views/app/player/resources/exercises/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    PlayerNavigationVue,
    PlayerResourcesNavigationVue,
    AppPlayerResourcesExercisesListIndexVue,
  },
})
export default class AppPlayerResourcesExercisesIndexVue extends Vue {}
